import { ArrayExtensions, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { AttributeDefinition, Customer, CustomerAttribute } from '@msdyn365-commerce/retail-proxy';
import { AccountAttributeDataTypeValue } from '../modules/account-profile/components/account-profile-attributes';

const getCustomerAttributeByName = (customer: Customer, name: string): CustomerAttribute | undefined => {
    if (!customer || !name || !ArrayExtensions.hasElements(customer.Attributes)) {
        return undefined;
    }

    const attribute = customer.Attributes?.find(x => x.Name?.toUpperCase() === name?.toUpperCase());

    return attribute;
};

export const getCustomerTextAttributeByName = (customer: Customer, name: string): string | null => {
    let value = null;
    const attribute = getCustomerAttributeByName(customer, name);
    if (attribute) {
        value = attribute?.AttributeValue?.StringValue ?? null;
    }
    return value;
};

export const checkIsInitializedCustomer = (customer?: Customer): boolean => {
    if (customer) {
        const isInitialized = getCustomerTextAttributeByName(customer, 'NEC_ECOM_DATA_INITIALIZED');
        return isInitialized?.toUpperCase() === 'YES';
    }
    return false;
};

export const setInitializedFlagOnCustomer = (customer: Customer, attributeDefinitions: AttributeDefinition[]): void => {
    if (customer && attributeDefinitions) {
        addOrUpdateTextAttribute(customer, attributeDefinitions, 'NEC_ECOM_DATA_INITIALIZED', 'YES');
    }
};

export const addOrUpdateTextAttribute = (customer: Customer, definitions: AttributeDefinition[], key: string, value: string): void => {
    const attributeDefinition = definitions?.find(data => data.Name === key);
    if (customer && attributeDefinition) {
        let formattedValue;
        switch (attributeDefinition.DataTypeValue) {
            case AccountAttributeDataTypeValue.Text:
                formattedValue = '';
                if (!StringExtensions.isNullOrWhitespace(value)) {
                    formattedValue = value.trim();
                }
                break;
            default: {
                console.log('attribute is not of text type, update will not be performed');
                return;
            }
        }

        customer.Attributes = customer.Attributes || [];
        const custmerAttributes: CustomerAttribute[] = customer.Attributes;
        const attribute = custmerAttributes.find(att => att.Name === key);

        if (attribute) {
            attribute.Attribute = undefined;
            attribute.DataTypeValue = attributeDefinition.DataTypeValue;
            attribute.Name = attributeDefinition.Name;
            attribute.RecordId = attributeDefinition.RecordId;
            attribute.Units = undefined;
            attribute.AttributeValue = {
                StringValue: formattedValue
            };
            attribute.ExtensionProperties = [];
            attribute.NameTranslations = [];
        } else {
            custmerAttributes.push({
                '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CustomerAttribute',
                Attribute: undefined,
                DataTypeValue: attributeDefinition.DataTypeValue,
                KeyName: attributeDefinition.Name,
                Name: attributeDefinition.Name,
                RecordId: attributeDefinition.RecordId,
                Units: undefined,
                AttributeValue: {
                    // @ts-expect-error
                    '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CommercePropertyValue',
                    StringValue: formattedValue
                },
                'ExtensionProperties@odata.type': '#Collection(Microsoft.Dynamics.Commerce.Runtime.DataModel.CommerceProperty)',
                ExtensionProperties: [],
                'NameTranslations@odata.type': '#Collection(Microsoft.Dynamics.Commerce.Runtime.DataModel.TextValueTranslation)',
                NameTranslations: []
            });
        }
    }
};
